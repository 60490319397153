$icon_height: 30px;
$icon_logo: 32px;
$min_height_header: 44px;
$icon_border_color: #06320a1f;

#header {
  width: 100%;
  position: fixed;
  overflow-x: hidden;
  z-index: 10;
  top: 0;

  .headerBackground {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .headerBackgroundImage {
    position: absolute;
    background-position: left;
    background-repeat: repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.05;
  }

  .headerMain {
    height: $min_height_header;
    padding: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: transparent;

    .headerMain_left,
    .headerMain_right {
      display: flex;
      gap: var(--space_item_primary);
      align-items: center;
      min-width: $icon_height;

      .headerMain_icon {
        position: relative;
        height: $icon_height;
        width: $icon_height;
        border-radius: var(--border_radius_button);
        padding: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          height: $icon_height;
          width: $icon_height;
        }
      }

      .headerMain_logo {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
          height: $icon_logo;
          width: auto;
        }
        span {
          font-weight: 800;
          letter-spacing: 0.06em;
          font-size: var(--font_size_title_regular);
          background: var(--color_success_gradiant);
          -webkit-background-clip: text;
          color: transparent;
        }
      }
    }

    .headerMain_home_right,
    .headerMain_home_left{
      min-width: unset;
    }
    .headerMain_home_left{
      width: fit-content;
    }
    .headerMain_home_right{
      width: 100%;
      .headerMain_right_wrap{
        width: 100%;
        justify-content: end;
        .headerMain_infoUser{
          width: 100%;
          justify-content: end;
          .headerMain_infoUser_name {
            width: 100%;
            text-align: right;
            max-width: 140px;
          }
        }
      }
    }

    .headerMain_no_login {
      width: calc(100% - 120px);
    }

    .headerMain_center {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      font-size: var(--font_size_title_regular);
      font-weight: var(--font_weight_primary);
      line-height: 24px;
    }

    .headerMain_language {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 4px;
      margin-right: 6px;
      border-radius: var(--border_radius_button);
      border: 1px solid $icon_border_color;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      min-width: 28px;

      span {
        text-transform: uppercase;
        font-weight: var(--font_weight_primary);
        font-size: var(--font_size_text_small);
      }
    }

    .headerMain_showInfoUser {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      .headerMain_button {
        padding: 4px 8px 4px 8px;
        border-radius: var(--border_radius_button);
        cursor: pointer;
        font-size: calc(var(--font_size_text_small) + 1px);
        font-weight: var(--font_weight_primary);
        height: 28px;
        min-width: 75px;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.headerMain_wallet {
  min-width: 200px;

  .headerMain_wallet_title {
    font-size: var(--font_size_text_regular);
    padding: 0 10px 6px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    width: fit-content;
  }
  .headerMain_wallet_value {
    font-size: var(--font_size_title_regular);
    font-weight: var(--font_weight_secondary);
    padding: 0 0 6px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  tr {
    &:last-child {
      .headerMain_wallet_title,
      .headerMain_wallet_value {
        border-bottom: none;
        padding-bottom: 0px;
      }
      .headerMain_wallet_value {
        color: var(--color_success);
      }
    }
  }
}
.headerMain_infoUser {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: var(--text_color_primary);

  .headerMain_infoUser_name {
    font-weight: var(--font_weight_primary);
    font-size: calc(var(--font_size_text_small) + 2px);
    max-width: 70px;
  }

  .headerMain_infoUser_balance {
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: var(--font_weight_primary);
    font-size: calc(var(--font_size_text_regular) + 2px);

    svg {
      height: 16px;
      width: 16px;
      min-width: 16px;
    }
  }
}

.headerMain_infoUser_popover {
  .ant-popover-arrow {
    .ant-popover-arrow-content::before {
      background: var(--background_color_secondary);
    }
  }
  .ant-popover-inner {
    background: var(--background_color_secondary);
    .ant-popover-inner-content {
      color: var(--text_color_primary_in_background);
    }
  }
}
