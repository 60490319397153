.BasicPopupConfirmWrap {
  background-color: var(--background_color_blur_opacity);
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .BasicPopupConfirmWrap_wrap {
    background-color: var(--background_color_component);
    border-radius: var(--border_radius_card);
    width: calc(var(--max_width_screen_project) - var(--space_rim_phone) * 2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    border-bottom: 2px solid var(--color_secondary);
    overflow: hidden;

    @media screen and (max-width: 480px) {
      max-width: calc(100% - var(--space_rim_phone) * 2);
      min-width: unset;
    }

    .BasicPopupConfirmWrap_wrap_head {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      background: var(--color_secondary_gradiant);
      box-shadow: 0px -1px 0px 0px #1e952a inset;

      .BasicPopupConfirmWrap_wrap_head_close {
        position: absolute;
        right: var(--space_item_primary);
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        cursor: pointer;
      }
      .BasicPopupConfirmWrap_wrap_head_background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: repeat;
        opacity: 0.05;
      }
    }

    .BasicPopupConfirmWrap_wrap_title {
      text-align: center;
      padding: 16px var(--space_card);
      font-size: var(--font_size_title_regular);
      border-bottom: 1px solid var(--border_card_color);
      font-weight: var(--font_weight_bold);
    }

    .BasicPopupConfirmWrap_wrap_body {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 175px;
      max-height: calc(95vh - 135px);
      padding: var(--space_card) var(--space_card);
    }

    .BasicPopupConfirmWrap_wrap_buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--space_item_between) var(--space_item);
      gap: 16px;

      .BasicPopupConfirmWrap_wrap_buttons_cancel {
        border: 1px solid var(--border_card_color) !important;
        background: var(--background_list_item_color) !important;
        color: var(--text_color_primary_light);
      }
    }
  }
}
