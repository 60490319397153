.BasicButton {
  height: 40px;
  border-radius: var(--border_radius_button);
  font-weight: var(--font_weight_primary);
  width: 100%;
}

.BasicButton:disabled {
  opacity: 0.5;
  cursor: not-allowed; /* Con trỏ chuột khi di chuột qua nút tắt */
}
