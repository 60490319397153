.BasicInput {
  font-size: calc(var(--font_size_text_regular) - 1px) !important;
  letter-spacing: -0.02em !important;
  color: var(--text_color_primary) !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--border_color_input) !important;
  height: 40px;
  padding: 4px 6px !important;

  &::placeholder {
    color: var(--text_color_placeholder) !important;
  }

  &:disabled,
  input:disabled {
    background-color: var(--background_color_disable) !important;
    color: var(--text_color_placeholder) !important;
  }

  .ant-input::placeholder {
    color: var(--text_color_placeholder) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.BasicInput[type='number']::-webkit-inner-spin-button,
.BasicInput[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BasicInput[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.ant-input-affix-wrapper {
  input {
    &:hover {
      border: none !important;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: var(--background_color_disable) !important;
}

.BasicInput_label {
  font-size: var(--font_size_text_small);
  color: var(--text_color_primary_light);
  font-weight: var(--font_weight_primary);
}
.BasicInput_error {
  font-size: var(--font_size_text_small);
  color: var(--color_danger);
  margin-top: 2px;
}

.BasicInput_2 {
  font-size: calc(var(--font_size_text_regular) - 1px) !important;
  letter-spacing: -0.02em !important;
  color: var(--text_color_primary) !important;
  border-radius: var(--border_radius_button) !important;
  border: 1px solid var(--border_color_input) !important;
  height: 32px !important;
  padding: 4px 6px !important;
  margin-top: 4px !important;
}
