// Pages
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../Page/ForgotPass/forgotPass.scss';
@import '../Page/Layout/index.scss';
@import '../Page/Login/index.scss';
@import '../Page/Notifications/index.scss';
@import '../Page/Register/register.scss';

// additional class
//reset
p {
  margin: 0;
  padding: 0;
}

// components
.btn-transparent {
  background-color: transparent;
  border: 1px solid var(--text_color_primary_in_button);
  border-radius: var(--border_radius_button);
  color: var(--text_color_primary_in_button);
  font-size: var(--font_size_text_regular);
  font-weight: var(--font_weight_secondary);
}

.btn-transparent:hover {
  background-color: transparent;
  border: 1px solid var(--text_color_primary_in_button);
  border-radius: var(--border_radius_button);
  color: var(--text_color_primary_in_button);
  font-size: var(--font_size_text_regular);
  font-weight: var(--font_weight_secondary);
}

.dk-title {
  font-weight: var(--font_weight_bold);
  margin-bottom: 10px;
}

.dk-text {
  margin-bottom: 10px;
}

.img-rounded {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  border-radius: var(--border_radius_card_small);
  position: relative;

  &--vertical {
    width: 60px;
    height: 60px;
  }
}

.avatar-img {
  position: relative;
}

.select-avatar {
  position: absolute;
  right: -6px;
  bottom: -6px;
}

.inputBasic {
  width: 100%;
  font-size: var(--font_size_text_regular);
  height: 40px;
}

.ant-form-item-label > label::after {
  content: '';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

// utils
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.text_two_lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Override library
.chartjs-tooltip {
  display: none !important;
}

.ant-message{
  .ant-message-notice-content{
    white-space: break-spaces;
    word-break: break-all;
    width: 100%;
    max-width: calc(var(--max_width_screen_project) - var(--space_rim_phone) * 2);
  
    @media screen and (max-width: 480px) {
      max-width: 97%;
      min-width: 97%;
    }
  }
}

.no_scroll_page{
  height: 100vh !important;
  overflow: hidden !important;
}
