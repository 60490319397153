.list-icon {
  margin-right: 4px;
}
.validate {
  &-item {
    font-size: var(--font_size_text_small);
    height: 22px;
    display: flex;
  }
  &-text {
    height: 22px;
    display: flex;
    align-items: center;
  }
}
.error-icon {
  svg {
    path {
      fill: var(--color_danger);
    }
  }
}
