.ant-modal,
.ant-modal-content {
  // height: calc(100vh + 150px) !important;
  // width: 100vw !important;
  // max-width: 375px !important;
  margin: 0 !important;
  top: 0 !important;
}
.ant-modal-body {
  height: calc(100vh) !important;
  padding: 0 !important;
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.address_wallet {
  display: -webkit-box !important;
  -webkit-line-clamp: 1; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
.ant-modal-header {
  padding: 0 !important;
  border: none !important;
  background-color: transparent;
  z-index: 1;
  position: relative !important;
}
.spin_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px) !important;
  max-height: 100vh !important;
  background: var(--text_color_primary_in_background);
}

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-input,
.ant-input:hover,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-input-number:hover {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #222222;
  border-radius: 2px;
}
.ant-input {
  font-size: var(--font_size_text_regular);
}

.ant-input-number-group-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: #222222;
  .ant-input-number,
  .ant-input-number:hover,
  .ant-input-number-focused {
    border: none;
  }
  .ant-input-number-group-addon {
    &:first-child {
      border-left-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
      border-right: 1px solid #222222;
    }
    &:last-child {
      background-color: #ffffff;
      border: none;
    }
  }
}

.ant-input-group-wrapper-status-error,
.ant-input-number-group-wrapper-status-error {
  border-color: #ff4d4f !important;
  .ant-input-group-addon:first-child {
    border-color: #ff4d4f !important;
  }
  .ant-input-number-group-addon:first-child {
    border-color: #ff4d4f !important;
  }
}

.ant-input-number-group-wrapper {
  display: block;
}

.change_language-selected {
  .ant-select-dropdown,
  .ant-select-item-option-selected {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .ant-select-item-option-active {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }
}

.ant-form-item-explain-error {
  text-align: center;
}

.ant-carousel .slick-dots li button {
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.ant-carousel .slick-dots-bottom {
  bottom: 20px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.ant-form-large .ant-form-item-label > label {
  height: 20px;
}

.btn-external {
  border-radius: 50%;
  border: 5px solid white;
  background-color: #f2f7ff;
  color: #5479bb;
}

.ant-picker-dropdown {
  z-index: 9999999;
}
