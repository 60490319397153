.BasicSelect {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid var(--border_color_input) !important;
  height: 40px;
  width: 100%;

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: calc(var(--font_size_text_regular) - 1px) !important;
    color: var(--text_color_primary) !important;
    letter-spacing: -0.02em !important;
  }

  .ant-select-selection-placeholder {
    color: var(--text_color_placeholder) !important;
  }

  .ant-select-selector {
    padding: 4px 6px !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--border_color_input) !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--color_primary) !important;
  box-shadow: none !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--background_color_active) !important;
}

.BasicSelect_search,
.BasicSelect_menu {
  padding: 6px 8px;
}

.ant-select-item {
  position: relative;
  font-size: calc(var(--font_size_text_regular) - 1px) !important;
  color: var(--text_color_primary) !important;
  letter-spacing: -0.02em !important;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    border-color: var(--border_color_divider);
    border-top-style: dashed;
    border-top-width: 1px;
  }
}

.BasicSelect_label {
  font-size: var(--font_size_text_small);
  color: var(--text_color_primary_light);
  font-weight: var(--font_weight_primary);
}

.BasicSelect_2 {
  border: none !important;
  border-radius: var(--border_radius_button) !important;
  height: 32px !important;
  margin-top: 4px !important;
  width: 100%;

  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: calc(var(--font_size_text_regular) - 1px) !important;
    color: var(--text_color_primary) !important;
    letter-spacing: -0.02em !important;
  }

  .ant-select-selection-placeholder {
    color: var(--text_color_placeholder) !important;
  }

  .ant-select-selector {
    border: none !important;
    padding: 4px 6px !important;
    border-radius: var(--border_radius_button) !important;
    border: 1px solid var(--border_color_input) !important;
    height: 32px !important;

    .ant-select-selection-search-input {
      height: 32px !important;
    }
    .ant-select-selection-item {
      line-height: 24px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 24px !important;
    }
  }
}
