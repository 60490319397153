//------------- max width ------------/
.max_width_screen_project {
  width: 100%;
  max-width: var(--max_width_screen_project);

  @media screen and (max-width: 480px) {
    max-width: 100%;
    min-width: 100%;
  }
}

//------------- background ------------/
.background_primary {
  background: var(--color_primary);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_primary);
}

.background_secondary {
  background: var(--color_secondary);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_secondary);
}

.background_danger {
  background: var(--color_danger);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger);
}

.background_success {
  background: var(--color_success);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_success);
}

.background_info {
  background: var(--color_info);
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_info);
}

.background_primary_gradiant {
  background: var(--color_primary_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_primary_gradiant);
}

.background_secondary_gradiant {
  background: var(--color_secondary_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_secondary_gradiant);
}

.background_danger_gradiant {
  background: var(--color_danger_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger_gradiant);
}

.background_danger_gradiant_popup {
  background: var(--color_danger_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_danger_gradiant);
}

.background_success_gradiant {
  background: var(--color_success_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_success_gradiant);
}

.background_info_gradiant {
  background: var(--color_info_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_info_gradiant);
}

.background_primary_outlined {
  background: var(--color_primary_light);
  color: var(--color_primary);
  border: 1px solid var(--color_primary_light_border);
}

.background_secondary_outlined {
  background: var(--color_secondary_light);
  color: var(--color_secondary);
  border: 1px solid var(--color_secondary);
}

.background_danger_outlined {
  background: var(--color_danger_light);
  color: var(--color_danger);
  border: 1px solid var(--color_danger);
}

.background_success_outlined {
  background: var(--color_success_light);
  color: var(--color_success);
  border: 1px solid var(--color_success);
}

.background_info_outlined {
  background: var(--color_info_light);
  color: var(--color_info);
  border: 1px solid var(--color_info);
}

.background_disable {
  background: var(--background_color_disable)  !important;
  color: var(--text_color_placeholder) !important;
  border: unset !important;
  svg {
    path{
      fill: var(--text_color_placeholder) !important;
    }
  }
}

.background_transparent {
  background: transparent;
}

.background_transparent_gradiant {
  background: transparent;
}

//background Top
.background_top_radius {
  border-radius: 0px 0px 28px 28px;
  overflow: hidden;
}

.isDisable {
  pointer-events: none;
  opacity: 0.6;
}

.word-break-all {
  word-break: break-word;
}

.text-align-right {
  text-align: right;
}

@media (max-width: 320px) {
  .otp {
    &__input-otp {
      input {
        width: 32px !important;
        height: 32px;
      }
    }
  }
}

.dot_notification {
  width: 16px;
  height: 16px;
  background-color: var(--color_danger);
  color: var(--text_color_primary_in_background);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--font_size_text_small) - 4px);
  font-weight: var(--font_weight_primary);
}

.text-title-primary {
  color: var(--text_color_primary);
  font-weight: var(--font_weight_primary);
}

.text-title-primary-light {
  color: var(--text_color_primary_light);
  font-weight: var(--font_weight_primary);
}

.text-extend-primary {
  color: var(--text_color_primary);
  font-weight: var(--font_weight_third);

}

.text-extend-primary-light {
  color: var(--text_color_primary_light);
  font-size: var(--font_size_text_small);
  font-weight: var(--font_weight_primary);
}
.text-extend-primary-light-third {
  font-size: var(--font_size_text_small);
  color: var(--text_color_primary_light);
  font-weight: var(--font_weight_third);
}
.flex-align-center{
  display: flex;
  align-items: center;
}
.flex-align-end{
  display: flex;
  align-items: end;
}

.btn-primary-gradiant {
  background: var(--color_primary_gradiant) !important;
  color: var(--text_color_primary_in_button);
  border: 1px solid var(--color_primary_gradiant);
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  font-weight: var(--font_weight_secondary);
  width: 100%;
  svg{
    path{
      fill: var(--color_primary_gradiant);
      stroke: var(--text_color_primary_in_button);
    }
  }
}

.btn-primary-soft {
  background: var(--background_color_secondy_btn) !important;
  color: var(--background_color_primary_btn);
  border: 1px solid var(--background_color_secondy_btn);
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  font-weight: var(--font_weight_secondary);
  width: 100%;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-secondary{
  font-weight: var(--font_weight_secondary) !important;
}
.divide-dot{
  margin: 16px 0px;
  width: 100%;
  border: 1px dashed var(--border_color_divider);
  
}

.font-size-small{
  font-size: var(--font_size_text_small);
}
.font_size_title_regular{
  font-size: var(--font_size_title_regular);
}

.btn-primary-select-active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border_color_select_green);
  background-color: var(--color_success_brighter) !important;
  font-weight: var(--font_weight_secondary);
  color: var(--text_color_primary) !important;
  border-radius: 2px;
  padding: 4px 8px;
}

.btn-primary-select-active.ant-btn:active{
  border: 1px solid var(--border_color_select_green) !important;

}

.btn-primary-select-active.ant-btn:hover, .ant-btn:focus{
  border: 1px solid var(--border_color_select_green) !important;
}

.btn-light-select-active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--border_color_input) !important;
  background: var(--background_color_white) !important;
  font-weight: var(--font_weight_third);
  border-radius: 5px;
  width: 45%;
  padding: 4px 8px;
}

.btn-light-select-active.ant-btn:active{
  border: 1px solid var(--border_color_input) !important;

}

.btn-light-select-active.ant-btn:hover, .ant-btn:focus{
  border: 1px solid var(--border_color_input) !important;
}

.btn-primary-select {
  border-radius: 2px;
  border: 1px solid var(--color_success_brighter);
  background-color: var(--text_color_primary_in_button) !important;
  font-weight: var(--font_weight_secondary);
  color: var(--text_color_primary) !important;
}
.custom-table .ant-table-wrapper {
  border-radius: 5px; 
}
.custom-table .ant-table-thead > tr > th {
  background-color: var(--background_color_secondary); 
  border: unset !important;
  color: var(--text_color_primary_in_button);
  font-weight: var(--font_weight_secondary);
}

.custom-table .ant-table-tbody > tr > td {
  color: var(--text_color_primary) !important;
  background-color: var(--background_table_body) !important;
  border-right: unset !important;
  border-color: var(--border_color_divider) !important;
}
.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 5px !important; /* Bo góc trái trên của header */
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 5px !important; /* Bo góc phải trên của header */
}
.custom-table .ant-table-summary > tr:first-child > td:first-child {
  border-bottom-left-radius: 5px !important; /* Bo góc phải dưới của phần tfoot */
}
.custom-table .ant-table-summary > tr:last-child > td:last-child {
  border-bottom-right-radius: 5px !important; /* Bo góc phải dưới của phần tfoot */
}

.custom-table .ant-table-summary > tr > td {
  background-color: var(--background_color_secondary); 
  border: unset !important;
  color: var(--text_color_primary_in_button);
  font-weight: var(--font_weight_secondary);
  text-align: center;
}

.amount-positive{
  color: var(--text_color_primary_bold_amount);
}

.amount-negative{
  color: var(--color_danger);
}

.description-note{
  font-size: var(--font_size_text_small);
  color: var(--color_info);
  padding: 4px 0;
}

.custom-radio .ant-radio-inner {
  border-color: var(--border_color_select);
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border-color: var(--color_success);
}

.custom-radio .ant-radio-checked .ant-radio-inner::after {
  background-color: var(--color_success);
}

.custom-radio .ant-radio:hover .ant-radio-inner,
.custom-radio .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--color_success);
}


.wrap-custom-radio {
  margin-bottom: 16px;
  border: 1px solid;
  border-color: var(--border_color_select);
  padding: 8px;
  border-radius: 5px;
  .text-radio-title{
      font-weight: var(--font_weight_secondary);
  }
  .text-radio {
      color: var(--text_color_primary_light);
  }
}
.wrap-custom-radio-active {
  border-color: var(--color_success);
  .text-radio-title{
      color: var(--text_color_primary) !important;
  }
}

.disable-content{
  background-color: var(--background_color_disable) !important;
  color: var(--text_color_placeholder) !important;
}
.disable-text{
  color: var(--text_color_placeholder) !important;

}
