$background_color_footer: linear-gradient(135deg, #8eff7b 0%, #20c831 62.5%, #18ae00 100%);

#Footer {
  width: 100%;
  margin: auto;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: end;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0px -4px 12px 0px #180d0114;

  .footer_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text_color_primary_light);
    font-size: calc(var(--font_size_text_small));
    font-weight: var(--font_weight_secondary);
    padding: 0 6px;
    gap: 2px;
    min-height: 56px;

    @media screen and (max-width: 280px) {
      padding: 0 4px;
      gap: 3px;
    }

    .footer_item_active {
      .footer_item_container {
        border-radius: 7px 7px 0 0;
        background-color: white;
      }
      p {
        color: var(--color_success) !important;
      }
    }

    .footer_item {
      position: relative;
      cursor: pointer;
      width: calc((100% - 76px) / 4);
      display: flex;
      align-items: end;
      margin-top: 2px;

      @media screen and (max-width: 280px) {
        width: calc((100% - 55px) / 4);
      }

      .footer_item_activeLine {
        position: absolute;
        top: -2px;
        width: 110%;
        left: -5%;
        height: 3px;
        border-radius: 0px 0px 8px 8px;
        background-color: var(--background_color_white);
      }

      p {
        text-align: center;
        color: var(--background_color_white);
      }

      .footer_item_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        padding: 0 0 4px 0;
        height: 90%;
      }
    }

    .footer_item_copybet {
      width: calc(((100% - 76px) / 4) + 12px);
    }

    .footer_item_personal {
      width: calc(((100% - 76px) / 4) - 12px);
    }

    .footer_item_icon {
      svg {
        height: 28px;
        width: 28px;
      }
    }

    .footer_item_special {
      cursor: pointer;
      width: 76px;
      position: relative;
      display: flex;
      align-items: end;
      justify-content: center;
      padding: 0 0 4px 0;

      p {
        text-align: center;
        color: var(--background_color_white);
        margin-top: 29px;
      }

      @media screen and (max-width: 280px) {
        width: 55px;
      }

      .footer_item_icon_special {
        position: absolute;
        padding: 9px;
        background-clip: padding-box, border-box;
        @media screen and (max-width: 280px) {
          padding: 10px;
        }

        background: var(--color_primary_gradiant);
        border-radius: 50%;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          height: 36px;
          width: 36px;
        }
      }
    }
  }

  .footer_container_image {
    --r: 32px;
    mask: radial-gradient(var(--r) at top, #0000 calc(100% - 1px), #000);
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-size: cover;
    opacity: 0.12;
    mix-blend-mode: plus-lighter;
    pointer-events: none;
  }
  .footer_container_background {
    --r: 32px;
    mask: radial-gradient(var(--r) at top, #0000 calc(100% - 1px), #000);
    position: absolute;
    width: 100%;
    height: 100%;
    background: $background_color_footer;
    pointer-events: none;
    z-index: -1;
  }

  .footer_blur_cta {
    position: absolute;
    height: 100vh;
    width: 100%;
    left: 0;
    bottom: 0;
    background: var(--background_color_opacity);
    z-index: -1;

    .footer_blur_cta_container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      width: calc(100% - var(--space_rim_phone) * 2);
      padding: var(--space_item_primary);
      justify-content: space-between;
      gap: var(--space_item_primary);
      bottom: 100px;
      background: var(--background_color_component);
      border: 2px solid var(--color_success);
      border-radius: var(--border_radius_card);

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 0;
        height: 0;
        height: 8px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid var(--background_color_component);
        z-index: 1;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 0;
        height: 0;
        height: 8px;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 14px solid var(--color_success);
      }

      .footer_blur_cta_container_button {
        font-size: var(--font_size_text_small);
        svg {
          margin-right: 2px;
        }
      }
    }
  }
}
