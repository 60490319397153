.notification {
  .headerMain_center {
    margin-left: 35px;
  }
  &_nap {
    height: 115px;
  }
  &_card {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: -56px;
    z-index: 11;
    position: absolute;
    width: 100%;
    &_boder {
      padding: 16px;
      background: var(--text_color_primary_in_background);
      border-radius: 12px;
      &_header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &_left {
          margin-right: 22px;
          position: relative;
          &_one {
            svg {
              width: 60px;
              height: 60px;
            }
          }
          &_two {
            position: absolute;
            top: 39px;
            right: -11px;
          }
        }
        &_right {
          &_one {
            font-size: var(--font_size_text_regular);
            font-weight: var(--font_weight_third);
            color: var(--text_color_placeholder);
          }
          &_two {
            font-size: var(--font_size_title_extra_large);
            font-weight: var(--font_weight_secondary);
            color: var(--text_color_primary);
            margin-top: 10px;
          }
        }
      }
      &_divice {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #f3ede7;
      }
      &_divices {
        margin-top: 30px;
        width: 100%;
        border: 1px dashed #f3ede7;
      }
      &_status {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_left {
          font-size: var(--font_size_text_regular);
          font-weight: var(--font_weight_third);
          color: var(--text_color_placeholder);
        }
        &_right {
          font-size: var(--font_size_text_regular);
          font-weight: var(--font_weight_secondary);
          color: #4fc814;
          padding: 3px 8px;
          background-color: #e6ffcd;
          border-radius: 20px;
        }
        &_rightf {
          font-size: var(--font_size_text_regular);
          font-weight: var(--font_weight_secondary);
          color: #d3170b;
          padding: 3px 8px;
          background-color: #ffe8e6;
          border-radius: 20px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &_rights {
          font-size: var(--font_size_text_regular);
          font-weight: var(--font_weight_secondary);
          border-radius: 20px;
        }
      }
    }
  }
  &_jay {
    background: var(--text_color_primary_in_background);
    min-height: 100vh;
    .headerMain_center {
      margin-left: 0px;
    }
  }
  // padding-bottom: 50px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 27px 20px 28px;
    background: var(--background_header_prize);
    p {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: var(--white-color);
      margin: 0;
      padding: 0;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    color: var(--white-color);
    align-items: center;
    &-right {
      margin-left: 12px;
      p {
        margin: 0;
        padding: 0;
        &:nth-child(1) {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
        }
        &:nth-child(2) {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
  .ant-table-thead {
    display: none;
  }
  @media (max-width: 576px) {
    &__header {
      position: absolute;
      right: 0;
      background: transparent;
      border: none;
      .title {
        visibility: hidden;
      }
      .action {
        background: var(--main-color);
        padding: 8px;
        border-radius: 50%;
        p {
          display: none;
        }
      }
    }
  }
  .notif-header {
    // height: 84px;
    &__detail {
      &__rmBtn {
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translate(-50%, -50%);
        font-size: 22px;
      }
    }
  }
  .notif-datetime {
    color: #5479bb;
  }
  .management__box-rounded-unset {
    margin-bottom: 100px;
  }
  .content-cut {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .noti_table {
    .ant-table-cell {
      padding: 0 !important;
    }
    .ant-table-wrapper {
      margin-bottom: 25px;
    }
    .noti_card {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      &_wig {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        &_left {
          position: relative;
          &_turn {
            border-radius: 50%;
            background-color: var(--color_danger);
            height: 11px;
            width: 11px;
            position: absolute;
            top: 20px;
            left: 23px;
          }
          &_date {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_placeholder);
          }
          &_dates {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_primary);
          }
          &_title {
            font-size: var(--font_size_text_regular);
            font-weight: var(--font_weight_secondary);
            color: var(--text_color_primary);
          }
        }
        &_right {
          &_date {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_placeholder);
          }
          &_dates {
            font-size: var(--font_size_text_small);
            font-weight: 400;
            color: var(--text_color_primary);
          }
          &_title {
            font-size: var(--font_size_text_regular);
            font-weight: var(--font_weight_secondary);
            color: var(--text_color_primary);
          }
          margin-left: 12px;
        }
      }
    }
  }
  div.ant-typography {
    margin-bottom: 0px;
  }
}

.SystemTabs {
  overflow: hidden;
  & .ant-tabs-nav {
    margin-bottom: 20px !important;
  }

  & .ant-tabs-nav .ant-tabs-nav-list {
    display: flex;
    width: 100%;
    background: var(--text_color_primary_in_background);
  }

  & .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  & .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
  }

  & .ant-tabs-tab {
    padding: 10px 0px;
  }

  & .ant-tabs-nav .ant-tabs-tab {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .ant-tabs-nav .ant-tabs-tab-btn {
    color: var(--text_color_primary_light);
    font-weight: var(--font_weight_secondary);
    font-size: var(--font_size_text_regular);
  }

  & .ant-tabs-tab-active div {
    color: var(--color_primary) !important;
  }
  & .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom: 2px solid #f6932b;
    color: var(--color_primary);
  }

  & .ant-tabs-ink-bar {
    background: var(--color_primary);
  }
}

.not_popup {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  &_text {
    padding-left: 5px;
    font-size: var(--font_size_text_regular);
    font-weight: var(--font_weight_secondary);
  }
}

.not_trash {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  &_text {
    padding-left: 5px;
    font-size: var(--font_size_text_regular);
    font-weight: var(--font_weight_secondary);
  }
}

.event_img {
  width: 100%;
  object-fit: cover;
  border-radius: 16px;
  aspect-ratio: var(--aspect_ratio_notification);
  overflow: hidden;
}

.sms-content {
  & .ant-typography.ant-typography-ellipsis {
    position: relative;
  }
  & .ant-typography.ant-typography-ellipsis::after {
    content: '... Hiển thị';
    position: absolute;
    right: 0;
    bottom: 0;
    background: white;
    color: #0050b3;
  }
}

@media all and (max-width: 281px) {
  .notification {
    .headerMain_center {
      margin-left: 30px;
    }
  }
}

.header-notofication {
  z-index: 5;
  display: flex;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;

  background-color: var(--background_color_white);
  height: 45px;
  padding: 0px 2px;

  .header-notification-item {
    flex-basis: calc(100% / 2);
    text-align: center;
    color: var(--text_color_placeholder);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    justify-content: center;
    position: relative;
    div {
      font-size: var(--font_size_text_regular);
    }
    &.active {
      background: var(--text_color_primary) !important;
      -webkit-background-clip: text !important;
      color: transparent !important;
      font-weight: var(--font_weight_primary);

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -15px;
        left: 18px;
        width: 80%;
        height: 4px;
        background: var(--color_success_gradiant);
        border-radius: 100px 100px 0px 0px !important;
      }
    }
  }
}
.notification {
  .message-box {
    cursor: pointer;
    background-color: var(--background_color_white);
    padding: 12px 8px;
    border-radius: var(--border_radius_card_small);
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    min-height: 60px;
  }

  .message-box-img{
    width: 100%;
    height: auto;
  }
  .message-box-title {
    max-width: 75%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .message-content {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Số dòng tối đa */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
  }
}
