body,
html {
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
  background-color: var(--background_color_browser);
}
body{
  overflow-y: scroll !important;
}

#root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  position: relative;
  width: 100%;
  font-family: var(--font_family);
  font-size: var(--font_size_text_regular);
  color: var(--text_color_primary);
}
