.swal2-shown {
  padding-right: 0 !important;

  &:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow: auto;
    overflow-y: scroll;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus,
  div:where(.swal2-container) button:where(.swal2-close):focus {
    box-shadow: none;
  }
}

.BasicAlertPopup_icon {
  border: none;
  z-index: 1;
  margin-top: var(--space_item_secondary);
  position: relative;
  height: 70px;
  width: 100%;
  margin-bottom: 70px;
  background: var(--background_color_secondary);
  border-radius: 12px 12px 0 0;
  margin-top: 0;

  .swal2-icon-content {
    position: absolute;
    width: 120px;
    height: 120px;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
}

.BasicAlertPopup_popup {
  border-radius: var(--border_radius_card);
  width: 90% !important;
  background: var(--background_color_component);
}

.BasicAlertPopup_title {
  color: var(--text_color_primary);
  font-size: var(--font_size_title_large);
  font-weight: var(--font_weight_primary);
  padding: 0 var(--space_item_primary) 8px;
}

.BasicAlertPopup_content {
  color: var(--text_color_primary);
  font-size: var(--font_size_text_regular);
  line-height: 20px;
  font-weight: var(--font_weight_secondary);
  padding: 0 var(--space_item_secondary);
}

.BasicAlertPopup_actions {
  width: 100%;
  margin: 20px 0 0 0;
  padding-left: 8px;
  padding-right: 8px;
}

.BasicAlertPopup_cancelButton {
  margin: 0 4px;
  width: calc(50% - 8px);
  padding: 8px;
  height: 40px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  border-color: var(--border_color_input) !important;
}

.BasicAlertPopup_confirmButton {
  margin: 0 4px;
  width: 100%;
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  box-shadow: none;
}

.BasicAlertPopup_confirmButton_withCancel {
  margin: 0 4px;
  width: calc(50% - 8px);
  height: 40px;
  padding: 8px;
  cursor: pointer;
  border-radius: var(--border_radius_button) !important;
  box-shadow: none;
}

.BasicAlertPopup_closeButton {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%) !important;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

// blur
.BasicAlertPopup_container {
  width: 100%;
  margin: 0 auto !important;
  z-index: 999999;
}

.fullHeightWidth {
  height: 100%;
  width: 100%;
}

.BasicAlertPopup_popup_no_icon {
  padding-top: var(--space_item_secondary);
}

.BasicAlertPopup_footer {
  position: absolute;
  display: block;
  bottom: 0;
  transform: translateY(100%);
  padding: 8px;
  border: none;
  width: 100%;
  text-align: center;
  left: 0;
  word-wrap: break-word;
  color: var(--background_color_component);
  font-size: var(--font_size_text_small) !important;
}

.swal2-confirm {
  background-color: unset;
}

.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown){
  overflow-y: unset;
  overflow: unset;
}
