.forgot {
  #login {
    .ant-row {
      display: block;
    }
  }
  .ant-form-item-label > label {
    font-size: var(--font_size_text_small);
  }
  .isDisable {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .ant-form-item {
    margin-bottom: 0px;
  }
  .tracker {
    position: relative;
    &-box {
      position: absolute;
      z-index: 2;
      border-radius: 2px;
      padding: 10px 5px 5px 5px;
      box-shadow: var(--box_shadow_tootip_input_login);
      background: var(--background_color_active);
    }
  }
  &__footer {
    font-size: var(--font_size_text_regular);
    text-align: center;
    line-height: 24px;
    margin-top: 20px;
    font-weight: 500;
    &--text {
      color: var(--color_primary);
      font-weight: var(--font_weight_secondary);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .error-mess {
    font-size: var(--font_size_text_small);
    color: var(--color_danger);
    margin-top: 2px;
    min-height: 20px;
  }
  &-btn {
    font-size: var(--font_size_text_regular);
  }
  &__title {
    font-weight: bold;
    font-size: var(--font_size_title_xxl);
    font-weight: var(--font_weight_primary);
    line-height: 40px;
    margin-bottom: 8px;
  }
  &-center {
    background-color: #fff;
    width: 100%;
    bottom: 0;
    padding: var(--space_item_secondary) var(--space_rim_phone);
    min-height: 100vh;

    .login__title {
      margin-top: 40px;
    }

    .ant-tabs-nav {
      display: none;
    }

    .ant-tabs.ant-tabs-top {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    .login__subTitle {
      margin-bottom: 36px;
    }

    &.full {
      height: 100%;
      border-radius: 0px;
    }
  }

  &-otp {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 120px;
      height: 120px;
    }
  }

  &-success {
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
      width: 140px;
      height: 140px;
    }
  }
  &-form{
    display: flex;
    flex-direction: column;
    gap: var(--space_item_secondary);
  }

  .login {
    &-center {
      padding: 38px;
      height: auto;
    }
  }
  .buttonConfirm {
    height: 28px;
    line-height: 16px;
    display: flex;
    font-size: var(--font_size_text_regular);
    justify-content: center;
    align-items: center;
    background: var(--color_primary_gradiant);
    border-radius: 4px;
    cursor: pointer;
    min-width: 60px;
    padding: 4px 8px;
    font-weight: var(--font_weight_primary);
  }
  @media (max-height: 740.5px) {
    height: calc(100% + 150px);
  }

  @media (max-height: 667.5px) {
    height: calc(100% + 230px);
  }

  @media (max-height: 414.5px) {
    height: calc(100% + 420px);
  }
}

.backIcon {
  svg {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}
@media (max-width: 480px) {
  .forgot {
    .ant-form-item .ant-form-item-label {
      padding: 0px !important;
    }
  }
}
