html {
  // cái này check nếu bé hơn thì sẽ 100% width lớn hơn thì sẽ mặc định tối đa 375px
  --max_width_phone: 480px;

  // font family
  --font_family: 'Barlow', sans-serif;

  //max width full screeen
  --max_width_screen_project: 375px;

  //font size
  --font_size_text_small: 12px;
  --font_size_text_regular: 15px;
  --font_size_title_regular: 18px;
  --font_size_title_large: 20px;
  --font_size_title_extra_large: 22px;
  --font_size_title_xxl: 30px;

  // font-weight
  --font_weight_bold: 700; // title, ...
  --font_weight_primary: 600; // title, ...
  --font_weight_secondary: 500; // button, ...
  --font_weight_third: 400; // button, ...

  //padding - margin
  --space_rim_phone: 12px; // rìa mép màn hình
  --space_card: 12px; // padding card
  --space_item_primary: 12px;
  --space_item_secondary: 20px;
  --space_item_tertiary: 40px; // 2 cụm khác nhau

  // border radius
  --border_radius_input: 2px;
  --border_radius_button: 5px;
  --border_radius_card: 14px;
  --border_radius_card_small: 7px;

  // image banner
  --aspect_ratio_banner: 16/9;
  --aspect_ratio_notification: 21/9;
  --aspect_ratio_square: 1/1;

  --box_shadow_tootip_input_login: 0px 2px 4px 0px #00000026;
}

[data-theme='PITGAME'] {
  // color new - changed
  --color_primary: #f6932b;
  --color_primary_light: #fff7e6;
  --color_primary_gradiant: linear-gradient(135deg, #ffd362 0%, #f6932b 53%, #f16601 100%);
  --color_primary_light_border: #ffd591;

  --color_secondary: #4fc81480;
  --color_secondary_light: #f5ffcc;
  --color_secondary_gradiant: linear-gradient(106.06deg, #fbffea 7.5%, #f5ffcc 62%, #deee7b 90%, #d2d610 100%);

  --color_success: #1bb92c;
  --color_success_light: #e6ffcd;
  --color_success_gradiant: linear-gradient(135deg, #8eff7b 0%, #199e27 62.5%, #107300 100%);
  --color_success_brighter: #eaf8e6;

  --color_danger: #dc1f18;
  --color_danger_light: #fff7e6;
  --color_danger_gradiant: linear-gradient(360deg, #dc1f18 0%, #f8954f 100%);

  --color_info: #0078cf;
  --color_info_light: linear-gradient(94.61deg, #149cfe 0.42%, #0066ff 48.89%, #0a61d4 98.81%);
  --color_info_gradiant: linear-gradient(135deg, #afecff 0%, #149cfe 24.78%, #0066ff 57.3%, #0a51ae 87.11%);

  --text_color_primary: #06320a;
  --text_color_primary_light: #6b7b6b;
  --text_color_placeholder: #7e867d;
  --text_color_primary_in_background: #ffffff; //card
  --text_color_primary_in_button: #ffffff;

  --text_color_primary_bold_amount: #0AC306;


  --background_color_primary: #f5f4f3;
  --background_color_secondary: #0a2406;
  --background_color_white: #ffffff;
  --background_color_opacity: rgba(255, 255, 255, 0.8); // rgba(255, 255, 255, 1) 80%
  --background_color_blur: #0a2406;
  --background_color_blur_opacity: rgba(10, 36, 6, 0.95); // #0A2406 95%
  --background_color_disable: #dfdfdf;
  --background_color_component: #ffffff;
  --background_color_active: #fff8f0;
  --background_color_reaction: #ffe8eb;
  
  --background_color_sub_green: #F2FFF4;

  --background_color_soft_peach: #FFE8D0;
  --background-color-heart-active: #FFE8EC;

  --background_color_secondy_btn: #FFD7C5;
  --background_color_primary_btn: #FF611D;

  --background_color_primary_thin: #FFF4D9;

  --color_gray: #707A83;
  --color_gray_light: #DEE1E3;


  --background_table_header: #0a2406;
  --background_table_body: #fafff8;

  --border_color_input: #d3d4d7;
  --border_color_select: #d9e7d9;
  --border_color_divider: #eaeaea;
  --border_color_item: #06320A1F;

  --border_color_select_green: #BAE1AF80;
  --color_button_bg: #FBF9F7;
  // màu nền ngoài bọc app
  --background_color_browser: #e9e9e9;
}
